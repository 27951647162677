<!--
 * @Description: 请修改成厚
 * @Version: 1.0
 * @Author: lvzhou
 * @Date: 2019-10-16 21:27:48
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-05-31 09:13:49
 -->
<template>
  <!--表格-->
  <div class="table_box">
    <!--表格配置-->
    <el-table
      v-loading="tableLoading"
      stripe :data="tableData"
      style="width: 100%"
      :header-cell-style="{
        background: '#EEF1F6',
        color: 'rgba(41,44,53,0.7)',
      }">
      <!--表格配置 end-->
      <template slot="empty">
        <div
          class="v2-table__empty-data v2-table__empty-border"
          style="width: 100%">
          <!--无数据显示-->
          <div
            class="v2-table__empty-default">
            <el-empty
              description="暂无数据" />
          </div>
        </div>
      </template>
      <!--循环条目配置-->
      <el-table-column
        v-for="(item, index) in columns"
        :key="index"
        :fixed="item.fixed"
        :prop="item.key"
        :label="item.title"
        :min-width="item.minwidth"
        :sortable="item.sortable"
        :filters="item.filters"
        :align="item.align"
        :filter-method="item.filters && filterHandler"
        :show-overflow-tooltip="item.tooltop">
        <!-- 结束 -->
        <!--操作-->
        <template slot-scope="scope">
          <template v-if="item.key == 'actions'">
            <!--按钮配置-->
            <!-- <el-button
              type="primary"
              size="mini"
              @click="$emit('edit',scope.row)">编辑</el-button> -->

              <div class="btnWrap">
                    <el-button  type="primary" size="mini" @click="$emit('keyMoney',scope.row)">余额</el-button>
                    <el-button type="danger" size="mini" @click="$emit('del',scope.row)">删除</el-button>
                    <el-button type="danger" size="mini" @click="$emit('edit',scope.row)">编辑</el-button>
              </div>
            
            <!--按钮配置 end-->
          </template>
          <!--序号-->
          <template v-else-if="item.key == 'index'">
                {{ scope.$index + 1 }}
          </template>
          <template v-else-if="item.key == 'key'">
                {{ maskString(scope.row.key) }}
          </template>

          <template v-else-if="item.key == 'mult'">
                <div>
                    <span> {{ scope.row.useNumber }}次</span>
                    <!-- <span v-if="scope.row.type == '3'">GPT3.5</span>
                    <span v-if="scope.row.type == '4'" style="color: rgb(255, 135, 54);">GPT4</span>
                    <span> / {{ scope.row.useNumber }}次</span>
                    <span v-if="scope.row.embedding == 1"> / 向量化 </span> -->
                </div>
          </template>

            <template v-else-if="item.key == 'availableTimes'">
                <div v-for="(items,indexs) in scope.row.modelData" :key="indexs" class="modelItem">
                    <span :class="items.openTime?'modelName-disable':'modelName-usable'">{{items.modelName}}</span>
                    <span v-if="items.openTime" class="openTime">（{{items.openTime}}秒后可用）</span>
                </div>
            </template>

            <template v-else-if="item.key == 'apiType'">
                <span v-if="scope.row.apiType=='1'">OpenAI</span>
                <span v-if="scope.row.apiType=='2'">微软Azure OpenAI</span>
                <span v-if="scope.row.apiType=='3'">智谱清言</span>
                <span v-if="scope.row.apiType=='4'">火山引擎</span>
                <span v-if="scope.row.apiType=='5'">通义千问</span>
                <div>{{scope.row.url}}</div>
            </template>

          <template v-else-if="item.key == 'type'">
            <span v-if="scope.row.type=='3'">GPT3.5</span>
            <span  v-if="scope.row.type=='4'">GPT4</span>
          </template>

          <template v-else-if="item.key == 'state'">
            <el-switch
              v-model="scope.row.state"
              :active-value="0"
              :inactive-value="1"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="$emit('changeState',scope.row)" />

          </template>

          <template v-else-if="item.key == 'options'">
            {{ scope.row.options | optionsFIlter }}
          </template>

          <template v-else>{{ scope.row[item.key] }}</template>

        </template>
      </el-table-column>
    </el-table>
    <!--分页配置-->
    <div
      style="margin-top: 20px; height: 40px;text-align: right;">
      <el-pagination
        class="fr"
        :current-page="pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange" />
    </div>
    <!--分页end-->
  </div>
</template>

<script>
// 引入组件及方法
import { mapGetters } from 'vuex'
export default {
  props: {
    // 表格数据
    tableData: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 表头项
    columns: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 加载状态
    tableLoading: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    tableClass: { type: String, default: '' }, // 表格样式
    pageSize: { type: Number, default: 30 }, // 当前页
    pageNumber: { type: Number, default: 1 }, // 每页显示条数
    total: { type: Number, default: 0 }, // 总数据
    isSelection: { type: Boolean, default: false } // 选中项
  },
  // 数据
  data() {
    return {
        // 表头
        optionsCheckbox:[
            {
                label:1,
                name:"对话"
            },{
                label:2,
                name:"向量化"
            },{
                label:4,
                name:"语音"
            },{
                label:8,
                name:"画图"
            }
        ]
    }
  },

  filters:{
    optionsFIlter(options){

        var arr = []

        var optionsCheckbox = [
            {
                label:1,
                name:"对话"
            },{
                label:2,
                name:"向量化"
            },{
                label:4,
                name:"语音"
            },{
                label:8,
                name:"画图"
            }
        ]

        optionsCheckbox.forEach((val)=>{
            var n = val.label & options
            if(n != 0){
                arr.push(val.name)
            }
        })

        return arr.join("，")

    }
  },
  methods: {
    //过滤器
    maskString(val) {
        let reg = /^(.{0}).*(.{10})$/;
        return val.replace(reg, "$1************$2");
    },
    // 过滤表头
    filterHandler(value, row, column) {
      const property = column['property']
      return row[property] === value
    },
    // 修改翻页
    handleSizeChange(e) {
      this.$emit('changePage', {
        pageNumber: this.pageNumber,
        pageSize: e
      })
    },
    // 设置每页条数
    handleCurrentChange(e) {
      this.$emit('changePage', {
        pageNumber: e,
        pageSize: this.pageSize
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.table_box {
  position: relative;
  .table_icon {
    position: absolute;
    width: 22px;
    height: 22px;
    border-radius: 4px;
    background: #dde2e8;
    text-align: center;
    line-height: 22px;
    z-index: 999;
    right: 20px;
    top: 15px;
    cursor: pointer;
  }
}
.btnWrap{
  // height: 70px;
}
.modelItem {
    
    .modelName-disable {
        color: #F56C6C;
    }
    .modelName-usable {
        color: #67C23A;
    }
    .openTime {
        margin-left: 5px;
        color: #F56C6C;
    }
}
</style>
